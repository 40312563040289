import { Card, Form } from 'react-bootstrap';

import Price from 'components/Price';
import PlaceholderAcc from 'assets/images/placeholder-acc.png';
import { ReactComponent as CheckIco } from 'assets/images/icons/Check.svg';

export const AccessoryItem = ({ data }) => {
    const { pictures } = data || {};

    return (
        <Card className="c-accessory">
            <CheckIco className="c-accessory__check" />
            <Card.Img src={pictures?.main || PlaceholderAcc} />
            <Card.Body className="c-accessory__body">
                <Card.Title className="c-accessory__title">
                    {data?.name}
                </Card.Title>
                <Card.Text className="c-accessory__price">
                    <Price price={data?.price} />
                </Card.Text>
            </Card.Body>
        </Card>
    );
};
