import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import axios from 'axios';

import { Accordion, Image } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { setTireLabelsData } from 'store/entities/data';

const TireLabels = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [labels, setLabels] = useState([]);
  const { specification } = useSelector(state => state.config);

  const { refetch: fetchData } = useQuery( // fetch tire labels data
    'tires-data',
    async () => {
      const res = await axios.get(`/labels/${specification}`);
      return res.data;
    },
    {
      enabled: false,
      onSuccess: res => {
        setLabels(res);
        dispatch(setTireLabelsData(res));
      },
    }
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!labels.length) return null;

  return (
    <Accordion.Item eventKey="accordionTires" className="c-accordion__item">
      <Accordion.Button className="c-accordion__toggler">
        {t('tire_labels_title')}
      </Accordion.Button>
      <Accordion.Body className="c-accordion__body">
        <div className="c-tires-descr">{t('tire_labels_text')}</div>
        <ul className="c-tires">
          {labels.map((item) => 
          <li className="c-tires__item" key={item.link}>
            <figure className="c-tires__img">
              <Image src={item.pic} />
            </figure>
            <a className="c-tires__label" href={item.link} target="_blank" rel="noreferrer">{t('tire_labels_more')}</a>
          </li>
          )}
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default TireLabels;
