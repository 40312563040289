import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Alert, Stack } from 'react-bootstrap';

import AccessoriesFilter from 'components/Accessories/AccessoriesFilter';
import AccessoriesCategory from 'components/Accessories/AccessoriesCategory';

const Accessories = ({ onChange }) => {
    const { t } = useTranslation();
    const { builderData } = useSelector(state => state.data);
    const { filters } = useSelector(state => state.app);

    const accessories = builderData?.accessories || {};
    const noData = !Object.keys(accessories).length;

    const renderCategory = () => Object.keys(accessories)
        .filter(item => !filters.length || filters.includes(item))
        .map(category => (
            <AccessoriesCategory
                key={category}
                category={category}
                data={accessories[category]}
                onChange={onChange}
            />
        ));

    return noData ? null : (<>
        <h5 className="c-section__subtitle mt-5">{t('accessories_list_title')}</h5>
        <Stack direction="horizontal" gap={3}>
            <p className="c-section__description">{t('accessories_list_subtitle')}</p>
            <Alert variant="info">
                <b>{t('consent_accessories_title')}</b> {t('consent_accessories_text')}
            </Alert>
        </Stack>
        <AccessoriesFilter />
        {renderCategory()}
    </>);
};

export default Accessories;
