import PlaceholderInterior from 'assets/images/placeholder-interior.png';

export const getExteriorPictures = (exteriors, code) => {
  const foundExteriors = exteriors?.filter(item => item.code === code);

  if (foundExteriors?.length) {
    return foundExteriors[0].pictures;
  }
  return {};
};

export const getActiveInteriorPicture = (interiors, code) => {
  const found = interiors?.find(item => item.code === code);
  return found?.pictures?.main || PlaceholderInterior;
};