import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import axios from 'axios';

import { Image } from 'react-bootstrap';

import useLocale from 'hooks/useLocale';

import ThreeSixty from 'components/360Viewer/index';
import PlaceholderCar from 'assets/images/placeholder-car.png';

const ThreeSixtyWrapper = () => {
  const locale = useLocale();
  const [pictures, setPictures] = useState([]);

  const { specification, colorCode, wheels } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);

  const ENV = window.ENV[window.location.hostname] || window.ENV.default;

  const imageMain = builderData.exteriors.find(item => item.code === colorCode)?.pictures.main;

  const { isFetching, refetch: fetchData } = useQuery(
    ['360pictures', specification, colorCode, wheels],
    async () =>
      axios.get(`/builder/specifications/${specification}`, {
        params: {
          brand: ENV.BRAND_NAME,
          'options.code': [colorCode, wheels].filter(item => item),
          language: locale
        },
      }),
    {
      enabled: false,
      onSuccess: res => {
        const pics = res.data.exterior.pictures['360'] || [];
        setPictures(pics);
      },
    }
  );

  useEffect(() => {
    if (!!colorCode) fetchData();
  }, [colorCode, wheels, fetchData]);

  if (!pictures.length || isFetching) {
    return (
      <figure className="c-vehicle__img">
        <Image src={imageMain || PlaceholderCar} />
      </figure>
    );
  }

  if (pictures.length && !isFetching) {
    return (
      <ThreeSixty
        key={pictures}
        amount={24}
        autoplay
        images={pictures}
        buttonClass="button"
        spinReverse
      />
    );
  }
};

export default ThreeSixtyWrapper;