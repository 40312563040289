import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Accordion, Badge } from 'react-bootstrap';

import AccessoriesCategory from 'components/Accessories/AccessoriesCategory';

const SelectedAccessories = () => {
  const { t } = useTranslation();
  const { accessories } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);

  const { accessories: options } = builderData || {};

  if (!accessories.length) return null;

  return (
    <Accordion.Item eventKey="accordionAccessories" className="c-accordion__item">
      <Accordion.Button className="c-accordion__toggler">
        <span className="c-accordion__title">{t('selected_accessories')}</span>
        <Badge pill bg="dark" className="c-accordion__count">{accessories.length}</Badge>
      </Accordion.Button>
      <Accordion.Body className="c-accordion__body py-3">
        {Object.keys(options).map(category => (
          <AccessoriesCategory
              key={category}
              category={category}
              data={options[category]}
              //hasMargin
              isConclusion
            />
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default SelectedAccessories;
