import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Badge, Button, Dropdown, Form, Stack } from 'react-bootstrap';

import { ReactComponent as Filter } from 'assets/images/icons/Filter.svg';
import { ReactComponent as Close } from 'assets/images/icons/Close.svg';
import { ReactComponent as Search } from 'assets/images/icons/Search.svg';

import { addFilter, removeFilter, setSearchValue } from 'store/entities/app';

const AccessoriesFilter = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { filters, selectedCategories, searchValue } = useSelector(state => state.app);
    const { builderData } = useSelector(state => state.data);

    const accessories = builderData?.accessories || {};

    const handleFilter = (eventKey, event) => filters.includes(eventKey) ?
        dispatch(removeFilter(eventKey)) :
        dispatch(addFilter(eventKey));

    const renderCount = () => {
        const count = filters.length + (selectedCategories !== 'all' ? 1 : 0);
        return count ? <Badge className="c-filter__count">{count}</Badge> : null;
    };

    const renderFilters = () => Object.keys(accessories).map(key => {
        const isFilterActive = filters.includes(key);
        const itemProps = {
            key: `filter-${key}`,
            eventKey: key,
            active: isFilterActive,
            className: 'c-filter__item'
        };
        return (
            <Dropdown.Item {...itemProps}>
                {key} {isFilterActive && <Close className="c-filter__close" />}
            </Dropdown.Item>
        );
    });

    return (
        <Stack gap={4} className="c-filter-container">
            <Dropdown
                onSelect={handleFilter}
                autoClose={false}
                className="c-filter"
            >
                <Dropdown.Toggle variant="link" className="c-filter__toggler">
                    <Filter className="c-filter__ico" /> {t('accessories_filter_default')} {renderCount()}
                </Dropdown.Toggle>
                <Dropdown.Menu className="c-filter__menu">
                    <Dropdown.Header className="c-filter__label">{t('accessories_filter_category')}</Dropdown.Header>
                    {renderFilters()}
                </Dropdown.Menu>
            </Dropdown>
            <Form.Group className="c-search-form">
                <Form.Control
                    placeholder={t('search')}
                    value={searchValue}
                    onChange={(e) => dispatch(setSearchValue(e.target.value))}
                    className="c-search-form__input"
                />
                <Button variant="link" className="c-search-form__btn">
                    <Search className="c-search-form__ico" />
                </Button>
            </Form.Group>
        </Stack>
    );
};

export default AccessoriesFilter;
