import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { setModalState } from 'store/entities/app';

import OfferForm from './OfferForm';
import LoadingSpinner from 'components/LoadingSpinner';
import classNames from 'classnames';

const formDefaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    //phoneCode: '371',
    phoneNr: '',
    location: '1',
    consents: [],
    modelFamilyName: ''
};

export const OfferModal = () => {
    const [step, setStep] = useState('form');
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { offerModal: isOpen, offerModalType } = useSelector(state => state.app);
    const methods = useForm({ defaultValues: formDefaultValues });

    const handleClose = () => {
        const payload = {
            modal: 'offerModal',
            newState: false,
        };
        dispatch(setModalState(payload));
        setStep('form');
    };

    const modalProps = {
        size: 'lg',
        className: 'offer-modal',
        show: isOpen,
        onHide: handleClose
    };

    return (
        <FormProvider {...methods}>
            <Modal {...modalProps}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t(`offer_modal_${offerModalType}`)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {step === 'form' && (
                        <OfferForm handleStepChange={setStep} />
                    )}
                    {step === 'loading' && (
                        <LoadingSpinner />
                    )}
                    {step === 'finish' && (
                        <Alert variant="success">
                            {t(`form_${offerModalType}_successful`)}
                        </Alert>
                    )}
                </Modal.Body>
            </Modal>
        </FormProvider>
    );
};