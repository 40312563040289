import { useTranslation } from 'react-i18next';

const useLocale = () => {
  const { i18n } = useTranslation();

  const domain = window.location.hostname.split('.').pop();
  const language = i18n.language;
  const supportedLngs = i18n.options.supportedLngs;
  const fallbackLng = i18n.options.fallbackLng?.[0] || 'en';
  const locale = supportedLngs.includes(domain) ? domain : fallbackLng;

  return [language, locale.toUpperCase()].join('_');
};

export default useLocale;