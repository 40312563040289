import { useSelector } from 'react-redux';
import _omit from 'lodash.omit';
import _pick from 'lodash.pick';

const useSalesFrontConfig = () => {
  const { config } = useSelector(state => state);
  const { rawBuilderData } = useSelector(state => state.data);

  return {
    brand: rawBuilderData.brand.name,
    id: Math.random() * (100000 - 1) + 1,
    title: rawBuilderData.name,
    specialPrice: rawBuilderData.specialPrice,
    price: rawBuilderData.price,
    priceExVat: false,
    purchasePrice: 0,
    specification: {
      make: _omit(rawBuilderData.brand, ['iri']),
      model: { ...rawBuilderData.model, group: '', type: '' },
      body: {
        id: rawBuilderData.bodies[0].id,
        name: rawBuilderData.bodies[0].name,
        bodyStyle: rawBuilderData.bodies[0].style,
        seatsNumber: rawBuilderData.bodies[0].seats,
        doorsNumber: rawBuilderData.bodies[0].doors,
      },
      engine: {
        ...rawBuilderData.engines.find(item => item.id === config.engine),
      },
      grade: { ...rawBuilderData.grades.find(item => item.id === config.gradeId), bigPicture: '' },
      exterior: {
        ..._omit(
          rawBuilderData.exteriors.find(item => item.code === config.colorCode),
          ['pictures']
        ),
        tilePicture: rawBuilderData.exteriors.find(item => item.code === config.colorCode)?.pictures
          ?.thumb,
        bigPicture: rawBuilderData.exteriors.find(item => item.code === config.colorCode)?.pictures
          ?.main,
        discountedPrice: 0,
        priceAdjustment: 0,
        purchasePrice: 0,
        vat: 0,
      },
      interior: {
        ..._omit(
          rawBuilderData.interiors.find(item => item.code === config.interior),
          ['pictures']
        ),
        tilePicture: rawBuilderData.interiors.find(item => item.code === config.interior)?.pictures
          ?.thumb,
        bigPicture: rawBuilderData.interiors.find(item => item.code === config.interior)?.pictures
          ?.main,
        discountedPrice: 0,
        priceAdjustment: 0,
        purchasePrice: 0,
        vat: 0,
      },
      endItemCode: config.specification,
      equipments: rawBuilderData.equipment.map(eq => ({
        ...eq,
        included: false,
        source: '',
        price: 0,
        discountedPrice: 0,
        code: '',
        vat: 0,
        type: 1,
      })),
      options: [
        _pick(
          rawBuilderData.wheels?.find(item => item.code === config.wheels),
          ['id', 'name', 'price']
        ),
        ...(rawBuilderData.options || [])
          .filter(item => config.extraPackages.includes(item.id))
          .map(({ id, name, price }) => ({ id, name, price })),
      ],
      accessories: [
        ...(rawBuilderData.accessories || [])
          .filter(item => config.accessories.includes(item.id))
          .map(({ id, name, price }) => ({ id, name, price })),
      ],
      services: [],
    },
  };
};

export default useSalesFrontConfig;
