import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Accordion, ListGroup, Badge } from 'react-bootstrap';

import Price from 'components/Price';

const SelectedPackages = () => {
  const { t } = useTranslation();
  const { extraPackages } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);

  const packages = (builderData.options || []).filter(option => extraPackages.includes(option.id));

  if (!extraPackages.length) return null;

  return (
    <Accordion.Item eventKey="accordionPackages" className="c-accordion__item">
      <Accordion.Button className="c-accordion__toggler">
        <span className="c-accordion__title">{t('selected_packages')}</span>
        <Badge pill bg="dark" className="c-accordion__count">{extraPackages.length}</Badge>
      </Accordion.Button>
      <Accordion.Body className="c-accordion__body">
        <ListGroup variant="flush">
          {packages.map(item => 
          <ListGroup.Item key={item.code}>
            <span className="list-group-label">{item.name}</span>
            <b className="list-group-value"><Price price={item.price} placeholder="&ndash;" /></b>
          </ListGroup.Item>
          )}
        </ListGroup>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default SelectedPackages;
